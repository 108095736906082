window.Spruce.store('install', {
    database_errors: [],
    verification_errors: [],
    output: null,
    processing: false,
    success: false,
    token: '',
    form: {
        // host: '127.0.0.1',
        // port: '3306',
        database: '',
        password: '',
        username: '',
        envato_username: '',
        envato_email: '',
        purchase_code: '',
    },

    install() {
        if (this.processing) return

        if (!this.validate()) return

        this.processing = true
        this.output = null

        this.checkDatabase()
    },

    validate() {
        this.database_errors = []
        this.verification_errors = []

        if (!this.form.database) {
            this.database_errors.push('database name required!')
        }
        if (!this.form.username) {
            this.database_errors.push('username required!')
        }

        if (!this.form.envato_username) {
            this.verification_errors.push('envato username required!')
        }
        if (!this.form.envato_email) {
            this.verification_errors.push('envato email required!')
        }
        if (!this.form.purchase_code) {
            this.verification_errors.push('purchase code required!')
        }

        if (!this.form.database || !this.form.username || !this.form.envato_username || !this.form.envato_email || !this.form.purchase_code) {
            return false
        }

        return true
    },

    checkDatabase() {
        fetch('/install/src/check-database.php', {
            method: 'POST',
            mode:   'cors',
            headers: {
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify(this.form)
        })
        .then(res => res.json())
        .then(data => {
            console.log('checkDatabase!')
            console.log(data)

            if (!data.success && data.error) {
                this.processing = false
                this.database_errors.push(data.error)
                return
            }

            if (data.success) {
                this.verify()
            }
        })
        .catch(error => {
            console.log('checkDatabase error!')
            console.log(error)
            this.processing = false
        })
    },

    verify() {
        this.form['domain'] = window.location.host
        this.form['url'] = window.location.href

        fetch('https://spack-admin.codedott.com/api/verify-installation', {
            method: 'POST',
            mode:   'cors',
            headers: {
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify(this.form)
        })
        .then(res => res.json())
        .then(data => {
            console.log('verify!')
            if (!data.success) {
                this.processing = false
                this.verification_errors.push(data.error)
                return
            }

            if (data.success) {
                this.download(data.token)
                this.token = data.token
            }
        })
        .catch(error => {
            console.log('error in verify!')
            console.log(error)
            this.processing = false
        })
    },

    download(token) {
        fetch('/install/src/download.php', {
            method: 'POST',
            mode:   'cors',
            headers: {
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({token})
        })
        .then(res => res.json())
        .then(data => {
            console.log('download response!')
            console.log(data)
            if (data.success) {
                this.extract()
            }
        })
        .catch(error => {
            console.log('error in download!')
            console.log(error)
            this.processing = false
            this.recordError('error in download')
        })
    },

    extract() {
        fetch('/install/src/extract.php', {
            method: 'POST',
            mode:   'cors',
            headers: {
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
            },
        })
        .then(res => res.json())
        .then(data => {
            console.log('extract response!')
            console.log(data)
            if (data.success) {
                this.config()
            }
        })
        .catch(error => {
            console.log('error in extract!')
            console.log(error)
            this.processing = false
            this.recordError('error in extract')
        })
    },

    config() {
        fetch('/install/src/config.php', {
            method: 'POST',
            mode:   'cors',
            headers: {
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify(this.form)
        })
        .then(res => res.json())
        .then(data => {
            console.log('config response!')
            console.log(data)
            if (data.success) {
                this.recipe()
            }
        })
        .catch(error => {
            console.log('error in config!')
            console.log(error)
            this.processing = false
            this.recordError('error in config')
        })
    },

    recipe() {
        fetch('/api/installation-recipe?token=' + this.token, {
            method: 'GET',
            mode:   'cors',
            headers: {
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
            },
        })
        .then(res => res.json())
        .then(data => {
            console.log('Recipe response!')
            console.log(data)
            if (data.success) {
                this.success = true
            }
        })
        .catch(error => {
            console.log('error in Recipe!')
            console.log(error)
            this.processing = false
            this.recordError('error in recipe')
        })
    },

    recordError(error) {
        fetch('https://spack-admin.codedott.com/api/installation/status', {
            method: 'POST',
            mode:   'cors',
            headers: {
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({
                error,
                token: this.token
            })
        })
        .then(res => res.json())
        .then(data => {
            console.log('recordError!')
            if (data.success) {
                console.log('recordError Done!')
            }
        })
        .catch(error => {
            console.log('error in recordError!')
            console.log(error)
            this.processing = false
        })
    },
})
